<template>
  <main class="main">
    <div class="container fluid">
      <section class="row registration my-0">
        <div class="col-lg-6 col-md-12  registration__left">
          <h2 class="registration__title">{{
            $t('REGISTRATION.payment_info')
          }}</h2>
          <form class="registration__form" autocomplete="off">
            <div class="registration__form-input">
              <input v-model="paymentInfo.first_name" type="text" :placeholder="$t('REGISTRATION.first_name') + '*'"
                class="mr-4" />
              <input v-model="paymentInfo.last_name" type="text" :placeholder="$t('REGISTRATION.last_name') + '*'" />
            </div>
            <input v-model="paymentInfo.phone" class="col-12 registration__input" type="number"
              :placeholder="$t('REGISTRATION.number') + '*'" />
            <input v-model="paymentInfo.email" class="col-12 registration__input" type="email"
              :placeholder="$t('REGISTRATION.email') + '*'" />
            <div class="registration__checkbox">
              <input type="checkbox" id="check" v-model="deliveryEnabled" />
              <label for="check" class="mb-0">{{
                $t('REGISTRATION.delivery_adress')
              }}</label>
            </div>
            <div v-if="deliveryEnabled" class="registration__delivery-info">
              <label for="country" class="d-flex flex-column mb-2">
                <p class="mb-1">
                  <span class="delivery-info__input-label">{{
                    $t('REGISTRATION.country')
                  }}</span>
                  <span class="delivery-info__input-required-sign">*</span>
                </p>
                <!-- <input
                  v-model="deliveryInfo.country"
                  id="country"
                  type="text"
                  placeholder="Страна"
                /> -->
                <v-autocomplete id="country" hide-details :items="$t('REGISTRATION.countries')" class="ma-0 pa-0"
                  v-model="deliveryInfo.country" autocomplete="disabled"></v-autocomplete>
              </label>
              <label for="address" class="d-flex flex-column mb-2">
                <p class="mb-1">
                  <span class="delivery-info__input-label">{{
                    $t('REGISTRATION.address')
                  }}</span>
                  <span class="delivery-info__input-required-sign">*</span>
                </p>
                <input v-model="deliveryInfo.address" id="address" type="text"
                  :placeholder="$t('REGISTRATION.home_number')" />
              </label>
              <label for="living-area" class="d-flex flex-column mb-2">
                <p class="mb-1">
                  <span class="delivery-info__input-label">{{
                    $t('REGISTRATION.locality')
                  }}</span>
                  <span class="delivery-info__input-required-sign">*</span>
                </p>
                <input v-model="deliveryInfo.livingArea" id="living-area" type="text" />
              </label>
              <label for="district" class="d-flex flex-column mb-2">
                <p class="mb-1">
                  <span class="delivery-info__input-label">{{
                    $t('REGISTRATION.region')
                  }}</span>
                  <span class="delivery-info__input-required-sign">*</span>
                </p>
                <input v-model="deliveryInfo.district" id="district" type="text" />
              </label>
              <label for="mail-index" class="d-flex flex-column mb-2">
                <p class="mb-1">
                  <span class="delivery-info__input-label">{{
                    $t('REGISTRATION.postcode')
                  }}</span>
                  <span class="delivery-info__input-required-sign">*</span>
                </p>
                <input v-model="deliveryInfo.mailIndex" id="mail-index" type="text" />
              </label>
              <label v-if="additionalField" for="inn" class="d-flex flex-column mb-2">
                <p class="mb-1">
                  <span class="delivery-info__input-label">{{
                    $t('REGISTRATION.inn')
                  }}</span>
                  <span class="delivery-info__input-required-sign">*</span>
                </p>
                <!-- v-model="deliveryInfo.inn" -->
                <input id="inn" type="number" maxlength="12" v-model="deliveryInfo.inn" @input="innInput" />
              </label>
            </div>
          </form>
        </div>
        <div class="col-lg-6 col-md-12 registration__right">
          <h2 class="registration__title">{{
            $t('REGISTRATION.your_order')
          }}</h2>
          <div class="registration__payment">
            <h3>{{ $t('REGISTRATION.coupon_code') }}</h3>
            <input class="payment__promocode" type="text" :placeholder="$t('REGISTRATION.coupon_placeholder')" />
            <button class="payment__btn">{{
              $t('REGISTRATION.apply_coupon')
            }}</button>

            <div class="payment__total-wrapper">
              <div class="payment__subtotal">
                <span>{{ $t('REGISTRATION.subtotal') }}</span>
                <span class="subtotal-price">${{ getItemsFullPrice() }}</span>
              </div>
              <div class="payment__shipping">
                <span>{{ $t('REGISTRATION.shipping') }}</span>
                <span class="shipping-price">${{ 0 }}</span>
              </div>
            </div>
            <div class="payment__total">
              <span>{{ $t('REGISTRATION.total') }}</span>
              <span class="total">${{ getItemsFullPrice() }}</span>
            </div>
            <p class="payment__text">
              {{ $t('REGISTRATION.rule') }}
            </p>
            <button :class="{ valid: isDeliveryInfoValid && isPaymentInfoValid }" class="place-order-btn"
              @click="makeOrder">
              {{ $t('REGISTRATION.place_order') }}
            </button>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapGetters } = createNamespacedHelpers('auth')
const { mapState: State_orderHistory, mapActions: Actions_orderHistory, mapMutations: Mutarions_orderHistory } = createNamespacedHelpers('orderHistory')
const {
  mapActions: Actions_brand_doubt,
  mapState: State_brand_doubt,
} = createNamespacedHelpers('brandDoubt')
const {
  mapState: State_cart,
  mapMutations: Mutations_cart,
} = createNamespacedHelpers('cart')
const { mapActions: Actions_alerts } = createNamespacedHelpers('alerts')
export default {
  name: 'Registration',
  data() {
    return {
      items: [],
      id: null,
      activeLang: '',
      paymentInfo: {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
      },
      deliveryEnabled: false,
      deliveryInfo: {
        country: '',
        address: '',
        livingArea: '',
        district: '',
        mailIndex: '',
        inn: '',
      },
      additionalField: false,
      shippingPrice: 20,
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    ...State_cart(['cartItems']),
    ...State_brand_doubt(['boughtBrands', 'orderDetail']),
    ...State_orderHistory(['reorderItems']),
    date() {
      return (
        new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()
      )
    },
    isPaymentInfoValid() {
      const invalidField = Object.keys(this.paymentInfo).find(
        (key) => !this.paymentInfo[key]
      )
      return !invalidField
    },
    isDeliveryInfoValid() {
      const deliveryInfoKeys = Object.keys(this.deliveryInfo)
      const deliveryInnOrNot = this.additionalField
        ? deliveryInfoKeys
        : deliveryInfoKeys.slice(0, deliveryInfoKeys?.length - 1)

      const invalidField = deliveryInnOrNot.find(
        (key) => !this.deliveryInfo[key]
      )
      return !invalidField
    },
  },
  watch: {
    'deliveryInfo.country'(val) {
      if (val === 'Россия') {
        this.additionalField = true
      } else {
        this.additionalField = false
      }
    },
    deliveryEnabled(val) {
      if (val) {
        const deliveryInfoCookie = this.$cookie.get('deliveryInfo')
        if (deliveryInfoCookie) {
          this.deliveryInfo.country = JSON.parse(deliveryInfoCookie).country
          this.deliveryInfo.address = JSON.parse(deliveryInfoCookie).address
          this.deliveryInfo.livingArea = JSON.parse(
            deliveryInfoCookie
          ).livingArea
          this.deliveryInfo.district = JSON.parse(deliveryInfoCookie).district
          this.deliveryInfo.mailIndex = JSON.parse(deliveryInfoCookie).mailIndex
          this.deliveryInfo.inn = JSON.parse(deliveryInfoCookie).inn
        }
      }
    },
    currentUser(val) {
      if (val && !this.$cookie.get('paymentInfo')) {
        this.paymentInfo.first_name = val.first_name;
        this.paymentInfo.last_name = val.last_name;
        this.paymentInfo.phone = val.phone;
        this.paymentInfo.email = val.email;
      };
    },
  },
  methods: {
    ...Actions_orderHistory(['createOrder', 'fetchOrderById']),
    ...Actions_alerts(['addAlert']),
    ...Mutations_cart(['SET_CART_ITEMS']),
    ...Mutarions_orderHistory(['SET_REORDER_ITEMS']),
    ...Actions_brand_doubt([
      'postBrandRemainder',
      'useBrandRemainder',
      'fetchOrderDetailsById',
    ]),
    innInput(e) {
      if (e?.target?.value?.length >= 11) {
        this.deliveryInfo.inn = e.target.value.substring(0, 12)
        return
      }
      this.deliveryInfo.inn = e.target.value
    },
    getItemsFullPrice() {
      let fullPrice = 0;
      let sizePrice = 0;
      this.items?.forEach((item) => {
        const brandsPrice = item.price_type === 'reyestr'
          ? item.selected_brands
          : item.soles.map((el) => el.selected_brands).flat()

        const count = item.sizes.reduce((acc, curr) => {
          return (acc += curr.count)
        }, 0);

        fullPrice += brandsPrice.reduce((acc, curr) => {
          if (curr.boughtBrandsKit) {
            return (acc += curr.brandPrice * count + (curr.boughtBrandsKit * curr.brandsPrice))
          } else {
            return (acc += curr.brandPrice * count)
          }
        }, 0)
        sizePrice += item.sizes.reduce((acc, curr) => {
          return (acc += curr.count * curr.price)
        }, 0)
      })
      this.$emit('getFullPrice', +fullPrice + +sizePrice)
      return (+fullPrice + +sizePrice)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    async makeOrder() {
      if (!this.isDeliveryInfoValid || !this.isPaymentInfoValid) return

      const delivery = JSON.stringify(this.deliveryInfo);
      const paymentInfo = JSON.stringify(this.paymentInfo);
      this.$cookie.set('paymentInfo', paymentInfo);
      this.$cookie.set('deliveryInfo', delivery);

      try {
        const orderResponse = await this.createOrder({
          items: this.items,
          shippingPrice: this.shippingPrice,
          delivery,
          paymentInfo
        })

        this.addAlert({ text: 'Заказ принят', type: 'success' })
        const savedBrands = localStorage.getItem('bought_brands') ? JSON.parse(localStorage.getItem('bought_brands')) : [];
        if (savedBrands.length) {
          await Promise.all(
            savedBrands.map(async (item) => {
              try {
                if (!item.boughtBrandsKit) {
                  await this.useBrandRemainder(item)
                } else {
                  const response = await this.postBrandRemainder(item)

                  if (response.status === 200) {
                    await this.useBrandRemainder(item)
                  }
                }
              } catch (error) {
                console.error(`Error processing item with id: ${item.id}`, error)
              }
            })
          )
        }

        localStorage.setItem('bought_brands', JSON.stringify([]))
        this.$router.push({ name: 'admin order complete' })

        this.SET_CART_ITEMS([])
        this.SET_REORDER_ITEMS([]);
      } catch (error) {
        this.addAlert({ text: 'Ошибка сервера', type: 'error' })
      }
    },
  },
  async mounted() {
    this.activeLang = JSON.parse(localStorage.getItem('language'));
    const cookieInfo = JSON.parse(this.$cookie.get('paymentInfo'));
    if (cookieInfo) {
      this.paymentInfo.first_name = cookieInfo.first_name;
      this.paymentInfo.last_name = cookieInfo.last_name;
      this.paymentInfo.phone = cookieInfo.phone;
      this.paymentInfo.email = cookieInfo.email;
    };
    this.items = this.$route.query.orderId ? this.reorderItems : this.cartItems;
  },
}
</script>

<style lang="scss" scoped>
.main {
  border: none;
}

.registration__delivery-info {
  select {
    position: relative;

    &:before {
      position: absolute;
      content: '^';
      right: 20px;
      top: 0;
    }

    // appearance: auto;
    padding-right: 30px;
  }
}

.delivery-info {
  &__input-label {
    // font-family: 'Alegreya Sans';
    font-weight: 500;
    color: #423f3d;
  }

  &__input-required-sign {
    color: #ea6c6c;
  }
}

.place-order-btn {
  cursor: default;

  &.valid {
    background-color: #cfebcc;
    color: #666666;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.registration {
  &__delivery-info {
    #country {
      border: none;
    }

    .v-select__slot {
      border: 0.7px solid #dcdcdc;
      border-radius: 2px;
    }
  }

  .v-expansion-panel-content__wrap {
    padding: 0 15px 14px 27px !important;
  }

  .v-text-field>.v-input__control>.v-input__slot:before,
  .v-text-field>.v-input__control>.v-input__slot:after {
    content: none;
  }

  .v-text-field>.v-input__control>.v-input__slot:after {
    content: none;
  }

  .v-input input {
    max-height: unset;
  }

  .v-text-field input {
    padding: 10px 30px;
  }

  .v-select:not(.v-text-field--single-line):not(.v-text-field--outlined) .v-select__slot>input {
    font-size: 14px;
    line-height: 15px;
  }
}

.v-autocomplete__content .v-select-list.v-list.v-sheet {
  background-color: #ffffff;
  border: 0.7px solid #dcdcdc;
}
</style>
